<!-- eslint-disable max-len -->
<template>
  <div
    v-if="!pending && pageData && !viewLeavingAnimationComplete"
    class="home"
    :class="[
      { 'home--landing-shown': atTop && !transitioning },
      { 'home--landing-showing': showingLanding && transitioning },
      { 'home--top-image-parallax': enableTopImageParallax },
      { 'view-leaving': viewLeaving },
    ]"
  >
    <header
      id="home-landing"
      class="home__landing overflow-hidden fade-in relative z-10 animation-delay-500"
      ref="homeLanding"
    >
      <div
        class="home__landing__wrap"
        ref="homeLandingWrap"
        data-rellax-speed="-6"
      >
        <div class="landing__background z-0 h-full relative">
          <div
            class="landing__background__video h-screen w-full absolute inset-0"
            ref="homeVideoBackground"
            data-rellax-speed="-12"
          >
            <video
              autoplay
              loop
              muted
              playsinline
              class="object-cover object-center h-full w-full z-0"
              ref="homeVideo"
              :poster="pageData.video_home.poster.url"
            >
              <source :src="pageData.video_home.src" type="video/mp4" />
            </video>

            <div
              class="landing__background__video__overlay mix-blend--multiply mix-blend absolute inset-0 z-10"
            ></div>
            <div
              class="landing__background__video__gradient absolute inset-0 z-20 mix-blend--overlay mix-blend"
            ></div>
            <client-only>
              <div
                v-if="!videoStatus && device.ios"
                class="landing__background__video__poster absolute inset-0"
              >
                <img
                  :src="pageData.video_home.poster.url"
                  class="object-cover object-center h-full w-full z-0"
                />
              </div>
            </client-only>
          </div>
        </div>
        <OverlayPattern
          blend="grid"
          :pulseOrder="1"
          :opacity="grid.lg ? '0.1' : '0.3'"
        />
        <OverlayPattern
          blend="difference"
          :pulseOrder="2"
          :opacity="grid.lg ? '0.1' : '0.3'"
        />
        <div
          class="landing__content z-10 flex flex-col flex-grow justify-start items-center mix-blend mix-blend--overlay h-screen absolute w-full top-0 overflow-hidden fade-up animation-delay-700"
        >
          <div class="landing__content__wrap flex flex-col items-center">
            <div
              class="landing__content__logo flex flex-col justify-center mt-auto"
            >
              <img
                :src="$constants.images.logo.svg.white"
                ref="homeLogo"
                class="landing__content__logo__img h-full"
                alt="Londolozi Logo"
                data-rellax-speed="-2"
              />
            </div>
            <div
              class="landing__content__slogan text-center flex flex-col mt-8 xxl:mt-10"
            >
              <span
                class="heading din-bold text-3xl leading-relaxed uppercase text-white"
                ref="homeSlogan1"
                data-rellax-speed="-2.1"
                >The Original</span
              >
              <span
                class="subheading text-sm italic text-grey-100 leading-6"
                ref="homeSlogan2"
                data-rellax-speed="-2.2"
              >
                Pioneering Experiential<br />
                Luxury Since 1926
              </span>
            </div>
            <button
              @click.prevent="showLanding(false)"
              class="icon icon-down p-4 text-4xl text-white mb-auto flex flex-shrink flex-grow-0"
              ref="homeButton"
              data-rellax-speed="-2.3"
              :class="{ hover: !screen.touch }"
            ></button>
          </div>
        </div>
      </div>
    </header>
    <div
      id="home-content"
      ref="homeContent"
      class="home__content h-screen w-full fade-in animation-delay-700"
    >
      <div
        class="home__content__wrap relative bg-white"
        :class="{ 'pt-16': !pageData.image_top }"
      >
        <TopImage
          v-if="pageData.image_top"
          :image="pageData.image_top"
          :gradientOverlay="false"
          :enableParallax="enableTopImageParallax"
          class="relative z-0"
        />
        <div class="content-index padding-app z-20 relative">
          <PageHeader
            :heading="pageData.title_display"
            :subheading="pageData.subtitle"
            :class="{ 'width-sm page-header--top-image': pageData.image_top }"
            class="mb-2"
          />
          <div class="main-content">
            <Excerpt
              :ellipsify="false"
              :pageType="'home'"
              :text="pageData.description"
              class="pt-12 mb-8"
            />
            <RenderHtml
              v-if="pageData.contents"
              :contents="pageData.contents"
            />
          </div>
        </div>
        <div class="page__bottom-nav padding-app mt-12" v-if="nextIndexPage">
          <div class="width-md-sm flex">
            <NuxtLink
              :to="'/en/' + nextIndexPage.route"
              class="btn btn-nav btn-next ml-auto"
            >
              <span>{{ nextIndexPage.text }}</span>
            </NuxtLink>
          </div>
        </div>
        <BottomVideo
          v-if="pageData.media.video_background"
          :preloadVideo="false"
          :videoId="pageData.media.video_background"
          @vimeo-error="pageData.media.video_background = false"
        />
        <BottomImage v-else :image="pageData.media.image_bottom" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import { ref, onMounted, onBeforeUnmount } from "vue";
import store from "@/store";
import debounce from "lodash.debounce";
import RenderHtml from "@/components/Utilities/RenderHtml.vue";
import PageHeader from "@/components/PageHeader.vue";
import { useViewTransitionFlags } from "@/composables/useViewTransitionFlags";
import Excerpt from "@/components/Excerpt.vue";
import TopImage from "@/components/TopImage.vue";
import BottomVideo from "@/components/BottomVideo.vue";
import BottomImage from "@/components/BottomImage.vue";
import Footer from "@/components/TheFooter.vue";
import OverlayPattern from "@/components/OverlayPattern.vue";
import { pageToPageId } from "@/util/pageMapping";
import { CONSTANTS } from "@/util/constants";
const {
  defaultTitle,
  defaultDescription,
  defaultImage,
  londoloziSuffix,
  stripTagsReg,
} = CONSTANTS.seo;
import { useParallax } from "@/composables/useParallax";
import { useMenuItems } from "@/composables/useMenuItems";
import { useDevice } from "@/composables/useDevice";
import tracking from "@/mixins/tracking";
import scrollTracking from "@/mixins/scrollTracking";

export default {
  name: "home",
  components: {
    RenderHtml,
    PageHeader,
    Excerpt,
    TopImage,
    BottomVideo,
    BottomImage,
    Footer,
    OverlayPattern,
  },
  mixins: [tracking, scrollTracking],
  async setup() {
    const scrollElId = ref("app-content");
    const scrollEl = ref(null);
    const homeLanding = ref(null);
    const homeContent = ref(null);
    const pending = ref(true);
    const transitioning = ref(false);
    const videoStatus = ref(null);
    const contentScrollTop = ref(null);
    const atTop = ref(false);
    const { device } = useDevice();
    const { menuItems } = useMenuItems();

    const viewLeaving = ref(false);
    const viewLeavingAnimationComplete = ref(false);

    const { togglePageDataReady, toggleViewLeaving } = useViewTransitionFlags(
      viewLeaving,
      viewLeavingAnimationComplete
    );

    const toggleDrawerState = () => {
      store.commit("toggleDrawerState");
    };

    onMounted(() => {
      initHome();
    });

    onBeforeUnmount(() => {
      scrollEl.value.removeEventListener("scroll", scrollDebounce);
      store.commit("toggleModifier", { key: "landing", val: false });
      window.removeEventListener("resize", onHomeResize);
    });

    onBeforeRouteLeave((to, from, next) => {
      toggleViewLeaving(["onBeforeRouteLeave"]);
      next();
    });

    const homeLogo = ref(null);
    const homeVideoBackground = ref(null);
    const homeSlogan1 = ref(null);
    const homeSlogan2 = ref(null);
    const homeButton = ref(null);

    const parallaxRefs = [
      homeVideoBackground,
      homeLogo,
      homeSlogan1,
      homeSlogan2,
      homeButton,
    ];

    const { createParallax } = useParallax();

    const id = pageToPageId["home"];
    const pageData = computed(() => store.getters.pageById(id));

    if (!pageData.value) {
      const { data } = await useFetch(`/api/index-page?name=home`);

      if (data?.value?.data && process.client) {
        store.dispatch("setPageData", {
          id,
          payload: data.value.data,
        });
      }

      togglePageDataReady();
      pending.value = false;
    } else {
      togglePageDataReady();
      pending.value = false;
    }

    const setContentScrollTop = () => {
      contentScrollTop.value = homeContent.value
        ? homeContent.value.offsetTop
        : null;
    };

    const showLanding = (val) => {
      if (val && !atTop.value) {
        store.commit("toggleModifier", { key: "landing", val });
        scrollToRef(homeLanding.value, val);
        return;
      }
      scrollToRef(homeContent.value, val);
    };

    const scrollToRef = (ref, up) => {
      Velocity(ref, "scroll", {
        duration: 900,
        container: scrollEl.value,
        easing: [0.76, 0, 0.24, 1],
        begin: velocityBegin,
        complete: () => velocityCallback(up),
      });
    };

    const scrollDebounce = debounce(
      () => {
        onHomeScroll();
      },
      50,
      { leading: false, trailing: true }
    );

    const velocityBegin = () => {
      console.log("%c%s", "color:slateblue;", "velocty begin");
      transitioning.value = true;
    };

    const velocityCallback = (up) => {
      console.log(
        "%c%s",
        "color:slateblue;",
        "velocity end completed toggle landing: show",
        up
      );
      atTop.value = scrollEl.value.scrollTop < contentScrollTop.value;
      store.commit("toggleModifier", { key: "landing", val: atTop.value });
      setTimeout(() => {
        transitioning.value = false;
      }, 150);
    };

    const onHomeScroll = () => {
      console.log(
        "%c%s",
        "color:lightblue;",
        "onHomeScroll this.transitioning",
        transitioning.value
      );
      if (transitioning.value) return;
      if (!contentScrollTop.value || contentScrollTop.value === 0)
        setContentScrollTop();

      // autoscroll enabled
      if (autoScrollEnabled.value) {
        if (atTop.value === null)
          atTop.value = scrollEl.value.scrollTop < contentScrollTop.value;
        store.commit("toggleModifier", { key: "landing", val: atTop.value });

        if (atTop.value) {
          if (scrollEl.value.scrollTop < contentScrollTop.value) {
            showLanding(false);
          } else {
            atTop.value = false;
            store.commit("toggleModifier", {
              key: "landing",
              val: atTop.value,
            });
          }
          return;
        }

        if (scrollEl.value.scrollTop < contentScrollTop.value) {
          showLanding(true);
          return;
        }
      }
      // autoscroll disabled
      if (scrollEl.value.scrollTop < contentScrollTop.value) {
        atTop.value = true;
      } else {
        atTop.value = false;
      }

      store.commit("toggleModifier", { key: "landing", val: atTop.value });
    };

    const onHomeResize = () => {
      setContentScrollTop();
    };

    const waitForParallaxRefs = (cb) => {
      if (homeLogo.value) {
        cb();
      } else {
        setTimeout(() => {
          waitForParallaxRefs(cb);
        }, 300);
      }
    };

    const initHome = () => {
      if (typeof window !== "undefined")
        window.addEventListener("resize", onHomeResize);

      scrollEl.value = document.getElementById(scrollElId.value);

      if (scrollEl.value) {
        scrollEl.value.addEventListener("scroll", scrollDebounce);
        atTop.value = scrollEl.value?.scrollTop === 0;
        store.commit("toggleModifier", { key: "landing", val: atTop.value });
      }

      waitForParallaxRefs(() => {
        createParallax(parallaxRefs);
      });
    };

    const waitForHomeVideoRef = () => {
      if (this.$refs.homeVideo) {
        this.$refs.homeVideo.addEventListener("playing", onVideoPlaying);
      } else {
        setTimeout(() => {
          waitForHomeVideoRef();
        }, 10);
      }
    };

    const enableTopImageParallax = computed(() => {
      if (process.client) {
        return !screen.touch && !screen.portrait && grid.xl;
      }
    });

    const nextIndexPage = computed(() => menuItems.value[0]);
    const showingLanding = computed(() => store.state.modifiers.landing);

    const autoScrollEnabled = computed(() => {
      if (typeof window === "undefined") {
        return false;
      }
      return !device.ipad && !device.ios && !device.iphone && !device.ipod;
    });

    const title = computed(() => {
      return (
        pageData.value?.seo?.title ||
        pageData.value?.title_display ||
        pageData.value?.title ||
        defaultTitle
      );
    });

    const description = computed(() => {
      return (
        pageData.value?.seo?.description.replace(stripTagsReg, "") ||
        pageData.value?.description?.replace(stripTagsReg, "") ||
        defaultDescription
      );
    });

    const image = computed(() => {
      return (
        pageData.value?.seo?.image.url ||
        pageData.value?.media?.image_banner?.url ||
        defaultImage
      );
    });

    const baseURL = computed(() => {
      if (typeof window === "undefined") {
        return null;
      }

      return process.env.NODE_ENV === "development"
        ? `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        : `https://${window.location.host}${window.location.pathname}`;
    });

    useSeoMeta({
      title: `${title.value}`,
      description: description.value,
      ogTitle: `${title.value} ${londoloziSuffix}`,
      ogDescription: description.value,
      ogImage: image.value,
      twitterTitle: `${title.value} ${londoloziSuffix}`,
      twitterImage: image.value,
      lang: "en",
      canonical: `${baseURL.value}`,
      link: [{ rel: "canonical", href: `${baseURL.value}` }],
    });

    return {
      atTop,
      pageData,
      scrollElId,
      scrollEl,
      homeContent,
      device,
      store,
      nextIndexPage,
      showingLanding,
      menuItems,
      homeLogo,
      homeVideoBackground,
      homeSlogan1,
      homeSlogan2,
      homeButton,
      pending,
      enableTopImageParallax,
      autoScrollEnabled,
      setContentScrollTop,
      showLanding,
      contentScrollTop,
      scrollToRef,
      velocityBegin,
      velocityCallback,
      onHomeScroll,
      videoStatus,
      gradientCycle: "10.50",
      videoRef: "homeVideo",
      observeRef: "homeLanding",
      scrollEl: null,
      transitioning: false,
      contentScrollTop: null,
      videoStatus: null,
      viewLeaving,
      viewLeavingAnimationComplete,
      onVideoPlaying() {
        if (!videoStatus || !videoStatus.value) {
          videoStatus.value = "has-played";
        }
      },
      scrollDebounce,
    };
  },
};
</script>

<style scoped lang="scss">
.home {
  &__landing {
    @apply h-screen;

    .landing__content {
      &__wrap {
        @include height-full-dynamic;
      }
      background: radial-gradient(
          ellipse at center,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.2) 60%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.1) 18%,
          rgba(0, 0, 0, 0.2) 100%
        );
      &__logo {
        &__img {
          width: 180px;
          max-width: 40vh;
          margin-top: 10dvh;
          transition: height 0.2s ease-out;
          filter: drop-shadow(0 1px 0px rgba(0, 0, 0, 1))
            drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
        }
      }
      &__slogan {
        margin-top: 6dvh;
        transition: height 0.2s ease-out;
        span {
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4),
            0 1px 3px rgba(0, 0, 0, 0.5);
        }
      }
      &__tiles {
        &__tile {
          @apply mx-4 flex flex-col items-center relative;
          height: 340px;
          background: hsla(0, 0%, 100%, 0.65);
          border: 1px solid #eee;
          &__action {
            @apply absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            i {
              @apply text-xl;
            }
          }
        }
      }
      button {
        margin-top: 6dvh;
        transition: height 0.2s ease-out;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4), 0 1px 3px rgba(0, 0, 0, 0.5);
        transition: 0.3s padding ease-out;
        &.hover:hover {
          @apply pb-2 pt-6;
        }
      }
    }
    .landing__background {
      &__video {
        @apply overflow-hidden;
        video {
        }
        &__gradient {
          animation: landing-gradient-fade-in 0.7s 0.5s 1 ease forwards;
          opacity: 0;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 18%,
            rgba(0, 0, 0, 0.1) 100%
          );
        }
        &__overlay {
          animation: landing-overlay-fade-in 0.7s 0.5s 1 ease forwards;
          background: linear-gradient(
            0deg,
            rgb(55, 152, 251) 0%,
            rgba(55, 152, 251, 0),
            rgba(55, 152, 251, 0)
          );
          opacity: 0;
          display: none;
          &.mix-blend--color {
            opacity: 0;
            background: linear-gradient(
              0deg,
              rgb(55, 152, 251) 0%,
              rgba(55, 152, 251, 0),
              rgba(55, 152, 251, 0)
            );
          }
        }
        &__poster {
          z-index: 9;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  &__content {
    .excerpt {
      width: 90%;
      max-width: 540px;
    }
    &__wrap {
      .content-index {
        transform: translate3d(0, 0, 0);
        :deep(.page-header--top-image) {
          .titles {
            @apply text-center pt-0 pb-10;
            .subheading {
              @apply text-base;
            }
            .heading {
              @apply text-2xl;
              letter-spacing: 0.18em;
            }
          }
        }
      }
    }
  }
  &--landing-shown {
    .home__content {
      .content-index {
      }
    }
  }
}

@screen xs {
  .home {
    &__landing {
      .landing__content {
        &__logo {
          &__img {
            width: 180px;
          }
        }
        &__slogan {
          .subheading {
            @apply text-base;
          }
        }
      }
    }
    &__content {
      .excerpt {
        width: 70%;
      }
      &__wrap {
        .content-index {
        }
      }
    }
  }
}

@screen sm {
  .home {
    &__content {
      .excerpt {
        width: 52%;
      }
    }
  }
}

@screen md {
  .home {
    &__content {
      &__wrap {
        .content-index {
          :deep(.page-header--top-image) {
            .titles {
              .heading {
                @apply text-3xl;
              }
            }
          }
        }
      }
    }
  }
}

@screen lg {
  .home {
    &__landing {
      .landing__content {
        &__slogan {
          .heading {
            @apply text-4xl;
          }
        }
      }
    }
  }
}

@screen xl {
  .home {
    &__landing {
      .landing__content {
        &__logo {
          &__img {
            width: 240px;
          }
        }
        &__slogan {
          .heading {
            @apply text-4xl;
          }
        }
      }
      .landing__background {
        &__video {
          &__overlay {
            display: block;
          }
        }
      }
    }
    &__content {
      &__wrap {
        .content-index {
          :deep(.page-header--top-image) {
            .titles {
              .heading {
                @apply text-4xl;
              }
            }
          }
        }
      }
    }
  }
}

@screen xxl {
  .home {
    &__content {
      &__wrap {
        .content-index {
          :deep(.page-header--top-image) {
            .titles {
              .heading {
                font-size: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (orientation: portrait) {
  .home {
    &__content {
      &__wrap {
        .content-index {
          margin-top: -17vw;
        }
      }
    }
  }
}

@screen md {
  .app--portrait {
    .home {
      &__content {
        &__wrap {
          .content-index {
            margin-top: -20vw;
          }
        }
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .home {
    &__content {
      &__wrap {
        .content-index {
          margin-top: -14vw;
        }
      }
    }
  }
}

@screen lg {
  .app--landscape {
    .home {
      &__content {
        &__wrap {
          .content-index {
            margin-top: -15vw;
          }
        }
      }
    }
  }
}

@screen xl {
  .app--landscape {
    .home {
      &__content {
        &__wrap {
          .content-index {
            margin-top: -18vw;
          }
        }
      }
    }
  }
}

@screen xl {
  .app--landscape {
    .home {
      &__content {
        &__wrap {
          .content-index {
            margin-top: -19.4vw;
          }
        }
      }
    }
  }
}
</style>
